<template>
    <div>

        <div class="header">
            <van-icon name="cross" @click="$router.go(-1)" />
            <div class="title" style="font-size:18px;font-weight:550">零钱通明细</div>
        </div>

        <div class="container">

            <div class="total" style="padding-left: 10px;padding-right: 10px;">
                <div class="total_time">
                    {{ totalTime }}
                </div>
                <div class="total_money">
                    <span>收入¥{{ returnFloat(totalIncome) }}</span>
                    <span>支出¥{{ returnFloat(totalCost) }}</span>
                </div>

            </div>
            <div class="list">
                <div class="list_item" v-for="(item, index) in list" :key="index + 'index'" @click="toBillDetail(item)">
                    <div class="first_row">
                        <div class="first_row_left">
                            {{ item.text }}
                        </div>
                        <div v-if="item.text != '零钱通转出-到零钱' && item.text != '零钱通转出-到银行卡'" class="first_row_right" :class="{ yl: item.type == 3 }">
                            {{ item.type != 3 ? '-' : '+' }} {{ returnFloat(item.value) }}
                        </div>
                        <div v-if="item.text == '零钱通转出-到零钱' || item.text == '零钱通转出-到银行卡'" class="first_row_right" >
                          - {{ returnFloat(item.value) }}
                        </div>
                    </div>
                    <div class="second_row">
                        <div class="second_row_left">
                            {{ item.time }}
                        </div>
                        <div class="second_row_right">
                            余额{{ returnFloat(item.remain) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import dayjs from 'dayjs';

export default {
    data() {
        return {
            totalTime: '',
            totalIncome: '',
            totalCost: '',
            list: {}
        }
    },
    created() {
        this.totalTime = dayjs().format('YYYY年MM月');
        if (localStorage.getItem('lingqiantong')) {
            const data = JSON.parse(localStorage.getItem('lingqiantong'));
           /* data.data1.time = dayjs().format('YYYY-MM-DD HH:mm:ss');
            data.data2.time = dayjs().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss');
            data.data3.time = dayjs().subtract(2, 'day').format('YYYY-MM-DD HH:mm:ss');
            data.data4.time = dayjs().subtract(3, 'day').format('YYYY-MM-DD HH:mm:ss');
            data.data5.time = dayjs().subtract(4, 'day').format('YYYY-MM-DD HH:mm:ss');
            data.data6.time = dayjs().subtract(5, 'day').format('YYYY-MM-DD HH:mm:ss');
            data.data7.time = dayjs().subtract(6, 'day').format('YYYY-MM-DD HH:mm:ss');
            data.data8.time = dayjs().subtract(6, 'day').format('YYYY-MM-DD HH:mm:ss');
            localStorage.setItem('lingqiantong', JSON.stringify(data));*/
            this.list = data;
        } else {
            this.list.data1 = {
                type: '3',
                text: '收益入账',
                value: '6.66',
                remain: '107890.00'
            };
            this.list.data2 = {
                type: '3',
                text: '收益入账',
                value: '6.66',
                remain: '356132.50'
            };
            this.list.data3 = {
                type: '3',
                text: '收益入账',
                value: '6.66',
                remain: '356132.50'
            };
            this.list.data4 = {
                type: '1',
                text: '微信转账',
                value: '190000.00',
                remain: '356132.50'
            };
            this.list.data5 = {
                type: '2',
                text: '扫二维码付款',
                value: '100000.00',
                remain: '356132.50'
            };
            this.list.data6 = {
                type: '1',
                text: '微信转账',
                value: '150000.00',
                remain: '356132.50'
            };
            this.list.data7 = {
                type: '3',
                text: '收益入账',
                value: '6.66',
                remain: '356132.50'
            };
            this.list.data8 = {
                type: '2',
                text: '扫二维码付款',
                value: '60000.00',
                remain: '656132.50'
            };
        }

        // 对 list 按照时间进行排序
        const sortedList = Object.keys(this.list).sort((a, b) => {
            const timeA = dayjs(this.list[a].time);
            const timeB = dayjs(this.list[b].time);
            console.log(timeA)
            console.log(timeB)
            return timeA.isBefore(timeB)? 1 : -1;
        }).reduce((obj, key) => {
            obj[key] = this.list[key];
            return obj;
        }, {});

        this.list = sortedList;

        let income = 0;
        let cost = 0;
        Object.keys(this.list).forEach(item => {
            if (this.list[item].type === 3) {
                income += parseFloat(this.list[item].value);
            } else {
                cost += parseFloat(this.list[item].value);
            }
        });
        this.totalIncome = income;
        this.totalCost = cost;
    },
    methods: {
        toBillDetail(item) {
            this.$router.push({
                path: '/self/service/licaibilldetail',
                query: {...item }
            });
        },
        returnFloat(num) {
    num = num.toString().replace(/,/g, ""); // 转成字符串类型  如能确定类型 这步可省去
      console.log(num)
      if (num.indexOf(".") !== -1) {
        let [integerPart, decimalPart] = num.split(".");
     
        if (decimalPart.length > 2) {
          decimalPart = decimalPart.substring(0, 2);
        } else if (decimalPart.length === 1) {
          decimalPart += "0";
        }
     
        num = `${integerPart}.${decimalPart}`;
      } else {
        num += ".00";
      }
     
      return num;
    }
    }
};
</script>
<style scoped>
.container {
    padding-top: 20px;
}
.first_row_left{
    font-size: 18px;
    font-weight: 600;
}
.first_row_right{
    font-size: 16px;
    font-weight: 600;
}
.header {
    position: relative;
    z-index: 99;
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0 15px 0 10px;
    line-height: 45px;
    background: #fff;
    opacity: 1;
    color: #000;
    user-select: none;
    -webkit-user-select: none;
    transition: all 0.3s linear;
    text-align: center;
    font-size: 17px;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Noto Sans CJK SC, WenQuanYi Micro Hei, Arial, sans-serif;

}
.second_row_left{
    font-size: 14px;
}
.total_time{
    font-size: 16px;
}
.title {
    flex: 1;
    margin-left: 17px;
}

.bill {
    font-weight: 400;
    font-size: 14px;
}
.second_row_right{
    font-size: 14px;
}
.total_money {
    display: flex;
    align-items: center;
    color: #a0a0a0;
    font-size: 14px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e2e2e2;
    padding-right: 10px;
}

.total_money span:nth-of-type(1) {
    margin-right: 8px;
}
.list {
    background: #fff;
    padding-left: 10px;
    padding-right: 10px;
}

.list_item {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e2e2e2;
}

.first_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom:0.4rem;
}

.second_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #a0a0a0;
}

.yl {
    color: rgb(204, 194, 2);
}
</style>